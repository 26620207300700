* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Maven Pro", sans-serif !important;
  overflow-x: hidden;
}

.pn-member__title {
  padding: 0 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  cursor: pointer;
}