.header {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 32px;
}
.header h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  color: #222222;
  margin-top: 20px;
}
.header p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #797979;
}
.form {
  width: 94%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 5px;
}
.form label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #58606e;
  margin-bottom: 6px;
}
.form input:focus {
  background-color: #fff;
}

.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.form input {
  width: 100%;
  height: 40px;
  padding: 8px;
  margin-bottom: 5px;
  width: 100%;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(228, 228, 228, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.01) !important;
}
.form input[type="radio"] {
  margin-right: 5px;
}
.errorMessage {
  height: 15px;
  color: red;
  font-size: 12px;
  /* margin-bottom: 10px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.LoginBtn {
  margin-top: 16px;
  width: 100%;
  height: 48px;
  border: none;
  background: linear-gradient(91.05deg, #00b894 0%, #00b894 128.05%);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 23px;
}

.divider {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.divider > div {
  margin: 0 11px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
}
.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #667085;
}
.googleBtn {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: #ffffff;
  color: #344054;
  border: 1px solid rgba(228, 228, 228, 0.6);
  box-shadow: 0px 12px 16px rgba(34, 34, 34, 0.04),
    0px 4px 6px rgba(34, 34, 34, 0.03);
  border-radius: 8px;
  height: 44px;
  width: 100%;
  outline: none;
  cursor: pointer;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facebookBtn {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: #1877f2;
  color: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appleBtn {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: #000;
  color: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #667085;
  margin-top: 35px;
  padding-bottom: 16px;
}
.footer span {
  color: #00b894;
  text-decoration: none;
  cursor: pointer;
}
.mx1 {
  margin: 0 8px;
}

.password_container {
  margin-top: 12px;
}
.errorHighlight {
  color: #f57e77 !important;
}
.error_border {
  border: 1px solid #f57e77 !important;
}

.warning_alert {
  margin-bottom: 0px !important;
  height: 44px;
  background: #fffcf5 !important;
  border: 1px solid #fec84b !important;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #b54708 !important;
  padding: 12px;
  margin-top: 15px;
}

.success_alert {
  margin-bottom: 0px !important;
  height: 44px;
  background: #f6fef9 !important;
  border: 1px solid #027a48 !important;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #027a48 !important;
  padding: 12px;
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .form {
    width: 90%;
    margin: auto;
    border-radius: 5px;
  }
  .header h2{
    font-weight: 600;
  }
  .header p{
    font-weight: 400;
  }
  .form label {
    color: #222222;
    font-weight: 400;
  }
  .LoginBtn {
    font-family: "Inter", sans-serif;
    font-weight: 500;
  }
  .footer {
    font-weight: 600;
  }
}
