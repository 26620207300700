.practitioner_info_speciality {
  margin-top: 33px;
  margin-left: 15px;
}

.card_container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(35, 46, 67, 0.03),
    0px 4px 6px -2px rgba(35, 46, 67, 0.03);
  border-radius: 13px !important;
  margin: 25px 0;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .card_container {
    width: 100%;
  }
}


.booking_card_container {
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 28px;
}

.card_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  align-self: flex-start;
  cursor: pointer;
  border-radius: 0px !important;
}

.card_image_desktop {
  border-radius: 200px;
  width: 100px;
  height: 100px;
  margin-top: 8px;
  align-self: flex-start;
  cursor: pointer;
}

.booking_card_image {
  border-radius: 200px;
  width: 92px;
  height: 92px;
  align-self: flex-start;
  cursor: pointer;
}

.card_image_practice {
  border-radius: 200px;
  width: 92px;
  height: 92px;
  align-self: flex-start;
  cursor: pointer;
}

.card_image_speciality {
  border-radius: 200px;
  width: 90px;
  height: 90px;
  margin-top: 24px;
  margin-left: 24px;
  align-self: flex-start;
  cursor: pointer;
}

.card_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #222222;
  cursor: pointer;
}

.card_title_speciality {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-left: 1px;
  line-height: 20px;
  color: #222222;
  cursor: pointer;
}

.card_text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  margin: 0;
}

.card_text span {
  font-size: 12px;
}

.flexBox {
  display: flex;
  gap: 6px;
  margin-top: 8px;
  margin-bottom: 3px;
}

.flexBox_desktop {
  display: flex;
  margin-top: 5px;
  margin-bottom: 3px;
}

.flexBox_rating {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 8px;
  margin-bottom: 3px;
}

.rating_container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
}

.rating_and_count_container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}

.star_rating_container {
  display: flex;
  gap: 1px;
}

.rating_container .rating {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #707a8a;
  position: relative;
  top: 0px;
  margin-top: 1px;
}

.rating_and_count_container .rating {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  color: #222222;
  position: relative;
  top: 0px;
}

.rating_and_count_container .rating_desktop {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  position: relative;
  top: 0px;
}

.rating_and_count_container .count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #797979;
  position: relative;
  top: 0px;
}

.rating_and_count_container .count_desktop {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #797979;
  position: relative;
  top: 0px;
}

.rating_container > svg {
  margin: 0.1rem;
}

.card_badge {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: flex-start;
  min-height: 30px;
}

.card_badge_item {
  background: #f5f7fa;
  border: 1px solid #eaecf0;
  border-radius: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 20px;
  color: #707a8a;
  padding: 1px 12px;
  margin: 0.2rem;
  user-select: none;
  cursor: pointer;
}

.map_container {
  margin-top: 1.5rem;
}

.map_location_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #797979;
  margin-bottom: 0;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map_location_title_booking {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #797979;
  margin-bottom: 8px;
  margin-top: 24px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map {
  width: 100%;
  height: 92px;
  border-radius: 6px;
  overflow: hidden;
}

.mapIframe {
  width: 100%;
  height: 100%;
  filter: grayscale(100%) invert(92%) contrast(83%) !important;
  border-radius: 6px;
}

.priceRange {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.priceRangeTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.priceRangeAmount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.date_schedule_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  cursor: pointer;
  height: 52px;
}

.date_schedule_container_no_appointments {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(225, 225, 225, 0.5);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  height: 52px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #222222;
}

.date_schedule_container::-webkit-scrollbar,
.time_slots_container::-webkit-scrollbar {
  display: none;
  cursor: pointer;
}

/* .date_schedule:nth-child(even) {
  background: transparent;
  border: 1px solid #c8d1e0;
} */
.date_schedule {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: 52px;
  border: 1px solid rgba(225, 225, 225, 0.3);
  box-shadow: 2px 4px 8px rgba(34, 34, 34, 0.04);
  border-radius: 6px;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}

.active_appointment {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  align-items: center;
  flex-direction: column;
  width: 44%;
  height: 52px;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
  background: #ffffff;
  border-radius: 6px;
}

.date_schedule:first-child {
  margin-left: 0;
}

.date_schedule:last-child {
  margin-right: 0;
}

/* .date_schedule:nth-child(even) > .date_schedule_day,
.date_schedule:nth-child(even) > .date_schedule_date {
  color: #afbacc;
} */

.date_schedule_day {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #222222;
}
.actionBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 6px;
}
.actionBtn {
  background: linear-gradient(91.05deg, #00b894 0%, #00b894 128.05%);
  color: #fff;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.date_schedule_date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #222222;
}

.time_slots_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.time_slots_item {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 44px;
  background: #00b894;
  border-radius: 6px;
  color: #ffffff;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  cursor: hand;
  user-select: none;
}

.time_slots_item:first-child {
  margin-left: 0;
}

.time_slots_item:last-child {
  margin-right: 0;
}

.p1 {
  padding: 1rem;
}

.mt20 {
  margin-top: 20px;
}

.rating_Text {
  margin-left: 4px;
  font-size: 11px;
  padding: 0px;
  margin-bottom: 1px;
}

.popularServices {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
  margin-inline: 24px;
  margin-bottom: 12px;
}

.popularServices_desktop {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
  margin-inline: 3px;
}

.serviceCard {
  background: #fff;
  border: 1px solid rgba(225, 225, 225, 0.5);
  box-shadow: 0px 12px 16px rgba(34, 34, 34, 0.04),
    0px 4px 6px rgba(34, 34, 34, 0.03);
  border-radius: 8px;
  margin-bottom: 16px;
  height: 85px;
  margin-inline: 24px;
  /* padding: 12px; */
}

.serviceTitle {
  position: relative;
  /* margin-bottom: 17px; */
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  left: 12px;
  top: 10px;
  font-size: 14px;
  color: #222222;
}

.serviceTime {
  position: relative;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  right: 12px;
  top: 12px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #797979;
}

.serviceBookBtn {
  background: linear-gradient(91.05deg, #00b894 0%, #00b894 128.05%);
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  height: 32px;
  width: 83px;
  padding: 4px 25px;
  outline: none;
  border: none;
  color: rgba(255, 255, 255, 1);
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.servicePrice {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #222222;
}

.view_specialities span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #222222;
  cursor: pointer;
}

.crossIcon {
  position: absolute;
  right: 35px;
  top: 32px;
  cursor: pointer;
  z-index: 10;
}

.crossIcon_speciality {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  z-index: 10;
  width: 24px;
  height: 24px;
}

.boooking_container {
  padding-top: 21px;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 8px;
  width: 100%;
}

.upper_modal_body {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 26px;
}

.lower_modal_body {
  display: flex;
  justify-content: space-between;
  height: 65%;
  margin-inline: 8px;
  align-items: flex-end;
}

.p_24 {
  padding-top: 24px;
  padding-inline: 16px;
  padding-bottom: 24px;
}

.padding_dekstop {
  padding: 24px;
}

@media only screen and (max-width: 767px) {
  .map_location_title {
    max-width: unset;
    white-space: unset;
  }

  .boooking_container {
    padding: 0;
  }
}
