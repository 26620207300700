.payment_container {
  width: 90%;
  margin: auto;
}

.payment_container_header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
  box-shadow: 0px 4px 4px rgba(14, 14, 14, 0.03);
  margin-bottom: 24px;
  padding: 1rem 24px;
}

.card_main {
  display: flex;
  justify-content: space-between;
}

.inputBox {
  width: 95%;
  margin: 0 10px;
}

.relativeClass {
  position: relative;
}
.icon {
  position: absolute;
  top: 7px;
  right: 15px;
}
.back_button > div {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #00b894;
  backdrop-filter: blur(4px);
  border-radius: 28px;
}
.payment_heading {
  width: 100%;
}
.payment_heading h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #222222;
  margin-top: 10px;
}
.payment_body {
  width: 50%;
  margin: auto;
  padding: 12px 0px;
}
.card_container {
  width: 100%;
}

.paymentCard {
  box-sizing: border-box;
  padding: 0.8rem;
  background: #ffffff;
  border: 1px solid rgba(225, 225, 225, 0.5);
  box-shadow: 0px 12px 16px rgba(34, 34, 34, 0.04),
    0px 4px 6px rgba(34, 34, 34, 0.03);
  border-radius: 16px;
  min-height: 220px;
}
.paymentCard label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
}
.saveBtnContainer {
  width: 50%;
  margin: auto;
}
.continue {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  color: #ffffff;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: linear-gradient(91.05deg, #00b894 0%, #00b894 128.05%);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: none;
  outline: none;
}
.continue:hover {
  opacity: 0.8;
}
.continue:active {
  border: none;
  background: #00b894 !important;
  color: #ffffff !important;
}
.continueClose {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  background: #00b894;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #00b894;
  border: none;
}
.continueClose:hover {
  background: #00b894 !important;
}
.continueClose:active {
  background: #00b894 !important;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.inputBox p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  margin-bottom: 6px;
  margin-top: 16px;
}
.mb12 {
  margin-bottom: 12px;
}
.mt32 {
  margin-top: 32px;
}
.mt23 {
  margin-top: 23px;
}
.mb16 {
  margin-bottom: 16px;
}

.custom_modal_size {
  /* width: 582px!important; */
  margin: 20px;
}

/* media query for mobile view  */
@media only screen and (max-width: 600px) {
  .payment_container {
    width: 90%;
    margin: auto;
  }
  .saveBtnContainer {
    width: 100%;
    margin: auto;
  }
  .collapse_container {
    width: 90%;
  }
  .selectBox {
    height: 40px;
  }
  .checkIcon {
    left: 85%;
  }
}

.Main_Modal {
  padding: 26px;
  padding-bottom: 24px;
  padding-top: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 45px !important;
  padding-bottom: 22px !important;
  width: 582px; */
}
.modal-content {
  border-radius: 1rem !important;
}

.tickImg {
  width: 45.33px !important;
  height: 31.17px !important;
}

.p_1 {
  font-size: 21px;
  text-align: center;
  font-family: "Inter", sans-serif;
  color: #00b894;
  line-height: 23.5px;
  margin-top: 29px;
  font-weight: 500;
}

.p_2 {
  font-size: 12px;
  text-align: center;
  font-family: "Inter", sans-serif;
  color: #667085;
  line-height: 23.5px;
  margin-top: -3px;
  font-weight: 500;
  padding-left: 2rem;
  padding-right: 2rem;
}

/* media query for mobile view  */
@media only screen and (max-width: 767px) {
  .mb2 {
    margin-bottom: 2rem;
  }
  .card_content {
    margin-top: 0rem;
  }
  .payment_body {
    width: 100%;
    /* min-width: 100%; */
    margin: unset;
  }
  /* .Main_Modal {
    width: 100% !important;
    padding: 24px !important;
  } */
}

.editBtn {
  text-align: flex-end;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #797979;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;
}
