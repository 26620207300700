@import "react-alice-carousel/lib/alice-carousel.css";
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.maven-pro-thin {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  color: #3D3D3D;
}

.maven-pro-light {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  color: #3D3D3D;
}

.maven-pro-regular {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #3D3D3D;
}

.maven-pro-medium {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500 !important;
  font-style: normal;
  color: #3D3D3D;
}

.maven-pro-bold {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: #3D3D3D;
}

.maven-pro-black {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  color: #3D3D3D;
}

.maven-pro-thin-italic {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: italic;
  color: #3D3D3D;
}

.maven-pro-light-italic {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: italic;
  color: #3D3D3D;
}

.maven-pro-regular-italic {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
  color: #3D3D3D;
}

.maven-pro-medium-italic {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
  color: #3D3D3D;
}

.maven-pro-bold-italic {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: italic;
  color: #3D3D3D;
}

.maven-pro-black-italic {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
  color: #3D3D3D;
}

.main_container_bg_color {
  height: 100dvh;
  background: #ffffff;
}

:root {
  --chat--light__hover--1: "#d1e8ff";
}

a {
  text-decoration: none !important;
}

.modal-content {
  border-radius: 1rem !important;
}

.booking_container_wrapper .modal-content {
  height: 680px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

/* MultiRangeSlider Component Styling */

.bar,
.bar-left {
  height: 1px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 100%;
  position: absolute;
}

.locationInputWrapper>div>div:first-of-type>div:first-of-type {
  padding-left: 2.3rem;
  /* background-color: #f2f2f2; */
  border-radius: 24px;
}

.locationInputWrapper>div>div {
  outline: none;
  box-shadow: none;
  border: none;
}

.locationInputWrapper>div>div:last-of-type {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.locationInputWrapper>div>div:last-of-type>div>div:not(:first-of-type) {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 8px;
}

.locationInputWrapper>div>div:last-of-type>div>div:not(:first-of-type):hover {
  background-color: #f9f9f9;
}

.locationInputWrapper>div>div:last-of-type>div>div:not(:first-of-type):active {
  background-color: #f9f9f9;
}

.locationInputWrapper>div>div>div>div:first-child {
  color: #222222 !important;
}

.locationInputWrapper:not(.outline-none)>div {
  border: 1px solid #e4e4e4;
}

.locationInputWrapper svg {
  display: none;
}

.css-26l3qy-menu {
  z-index: 1000 !important;
}

.css-1wy0on6 {
  display: none !important;
}

.css-tlfecz-indicatorContainer svg {
  fill: #969696;
  margin-right: 2px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-yk16xz-control {
  border: none !important;
  border-radius: 8px !important;
}

/* .css-1hwfws3{
  border-radius: 25px;
  background-color: #f2f2f2;
} */

.rounded-photo-lg {
  border-radius: 50%;
  width: 124px;
  height: 124px;
  object-fit: cover;
}

.rounded-photo-sm {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
}

.StripeElement {
  border: 1px solid #e4e4e4;
  color: #222222;
  font-weight: 400;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  padding: 12px 6px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  max-height: 42px;
}

.cursor-pointer {
  cursor: pointer;
}

/* .PhoneInputCountry {
  display: none !important;
} */

.container_large {
  width: 90%;
  margin: 0 auto;
}

.modal-dialog {
  max-width: 580px !important;
}

.tab_margin {
  margin-top: 3rem;
  visibility: hidden;
}

.modal-dialog-centered {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* applied on large devices */
@media only screen and (min-width: 767px) {
  .desktop-hide {
    display: none !important;
  }
}

/* applied on mobile and tablet view */
@media only screen and (max-width: 767px) {
  .modal {
    margin: 0px !important;
  }

  .mobile-hide {
    display: none !important;
  }

  .container_large {
    width: 100%;
    margin: 0;
  }

  .main_container_bg_color {
    background: #f9f9f9;
    height: fit-content;
  }
}

select:focus {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}

/* //date picker  */
.datePickerWrapper {
  display: none;
}

/* .MuiDayCalendar-weekContainer .Mui-selected {
  background-color: #00b894 !important;
  color: #fff;
} */

/* .MuiPickersLayout-actionBar .MuiButtonBase-root {
  color: #00b894;
} */

#html5-qrcode-button-camera-permission,
#html5-qrcode-button-camera-start,
#html5-qrcode-button-camera-stop,
#html5-qrcode-button-file-selection {
  /* background: #00b894; */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 15px;
}

/* PlayerCard.css */
.player-card {
  background-color: #343a40;
  color: white;
  padding: 10px;
  border-radius: 10px;
  color: black;
}

.player-card-image {
  border-radius: 200px;
  width: 132px !important;
  height: 132px;
  align-self: flex-start;
  cursor: pointer;
}

.player-card-body {
  margin-left: 10px;
}

.player-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.player-team,
.player-position,
.player-school,
.player-city {
  font-size: 0.9rem;
  margin: 2px 0;
}

.player-team {
  font-weight: bold;
  color: #007bff;
}

.MuiPickersCalendarHeader-label {
  width: fit-content;
}

.pn-channel-list {
  background-color: #f9f9f9;
}

.pn-channel-list .pn-channel {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 10px;
}

.pn-channel-list .pn-channel:active {
  background-color: #f3f3f3;
}

.pn-msg-input {
  background-color: #fefefe;
}


.Mui-selected.MuiMenuItem-root {
  /* background-color: #00b894 !important;  */
  color: white !important; /* Ensure text is readable */
}
