.continue {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  color: #ffffff;
  width: 25%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.continue:hover {
  opacity: 0.8;
}

.continue:active {
  border: none;
  background: #00b894;
  color: #ffffff !important;
}

.image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 20px;
  background-color: #00b89342;
}

.flexRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.disabledText {
  margin-top: 6px;
  color: #797979;
}
