.header_container {
  width: 100%;
  position: sticky;
  z-index: 100;
  background: #ffffff;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
  box-shadow: 0px 4px 4px rgba(14, 14, 14, 0.03);
  margin-bottom: 32px;
}
.container_body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0;
  margin-top: 14px;
}

.NotFoundPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60vh;
}

.NotFoundPage p:first-of-type {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #222222;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 37px;
}

.NotFoundPage p:last-of-type {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  color: #797979;
  margin-bottom: 32px;
  width: 70%;
}
