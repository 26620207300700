.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #f9f9f9;
  text-align: center;
}

.title {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.title h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  color: #222222;
  margin: 0;
}

.back_button {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back_button > img {
  width: 28px;
  height: 28px;
}

.right_button {
  width: 60px;
  height: 60px;
  padding: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
