.tournament_detail_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.heading_container {
  margin-bottom: 24px;
  text-align: center;
}

.heading {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 16px;
}

.facilities_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin-top: 16px;
}

.facility_badge {
  background-color: #00b894;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.details_card {
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 8px !important;
}

.section_title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 2px solid #e0e0e0;
}

.details_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.detail_item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.icon {
  color: #00b894;
  font-size: 20px;
  margin-top: 4px;
}

.address_link {
  color: #2c3e50;
  text-decoration: none;
  &:hover {
    color: #00b894;
  }
}

.join_section {
  padding: 24px;
  margin-top: 24px;
  border-radius: 8px !important;
}

.join_form {
  max-width: 600px;
  margin: 0 auto;
}

.form_group {
  margin-bottom: 24px;
}

.form_label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #2c3e50;
}

.search_wrapper {
  position: relative;
}

.search_input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.search_dropdown {
  position: relative;
}

.dropdown_menu {
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.dropdown_item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown_item:hover {
  background-color: #f0f0f0;
}

.paper {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 0 2px;
  height: 400px;
  overflow: auto;
  box-shadow: 0px 0px 3px 0px #00000026;
}

.paper1 {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 0 2px;
  box-shadow: 0px 0px 3px 0px #00000026;
}

.accordion {
  background-color: white;
  box-shadow: none;
}

.accordionSummary {
  background-color: #f5f5f5;
  color: black;
}

.roundTitle1 {
  font-weight: bold;
  font-size: 16px;
}

.accordionDetails {
  overflow: auto;
  max-height: 300px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.matchCard {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 3px 0px #00000026;
}

.matchCard1 {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 3px 0px #00000026;
}

.matchHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.teams {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
}

.matchTime {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  transition: border-color 0.2s;

  &:focus {
    border-color: #00b894;
    outline: none;
  }
}

.error_text {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 4px;
}

.create_btn {
  width: 100%;
  padding: 12px;
  background: rgb(0, 184, 148);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;
  margin-top: 12px;
}

.create_btn:hover {
  background: rgb(0, 164, 128);
}
