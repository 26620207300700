.modalTitle {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #222222;
  text-align: center;
}

.modalMessage {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #667085;
  margin-bottom: 15px;
}

.actionBtn {
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 12px 0px;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;
}
.actionBtnPrimary {
  background: #00b894;
  color: #ffffff;
  border: none;
}

.Main_Modal {
  position: relative;
  padding-bottom: 24px;
  padding-top: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cancelModalIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  cursor: pointer;
}
